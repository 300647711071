<template>
  <EmptyBasket :alt-layout="altLayout" />
</template>
<script setup lang="ts">
defineProps({
  altLayout: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const EmptyBasket = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('./vdv/emptyBasket.vue');
  } else {
    return import('./minilu/emptyBasket.vue');
  }
});
</script>
